export default {
  methods: {
    randomInt(max, min = 0) {
      if (min != 0) {
        return Math.floor(Math.random() * (max - min) + min);
      } else {
        return Math.floor(Math.random() * max);
      }
    },
    randomFloat(max, min = 0) {
      if (min != 0) {
        return Math.random() * (max - min) + min;
      } else {
        return Math.random() * max;
      }
    },
    raffleTime(duration) {
      var length;
      if (duration.length > 1) {
        length = this.randomInt(parseInt(duration[0]), parseInt(duration[1])); // Number of seconds the contest should take
      } else {
        length = parseInt(duration[0]); // Number of seconds the contest should take
      }
      return length;
    },
    getCoords(elem) {
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
  },
};
